import React from 'react'
import { Field } from 'formik';
import styled from 'styled-components'
import Thumb from './Thumb'
const File = ({name,placeholder,label,seteador}) => {
    return(<>
        <Field name={name}>
            {({
                field,
                form,
                meta,
            }) => (
                <InputWrap>
                    {label && 
                        <label>{label}</label>
                    }
                    <MyStyledInput 
                        type="file"
                        name={name}
                        id={name}
                        placeholder={placeholder} 
                        className={meta.touched && meta.error ? 'error' : ''}
                        onChange={e => {
                            form.setFieldValue(name, e.currentTarget.files[0])
                        }}
                        
                    />

                    
                    <Thumb img={meta.value} nombre={name}/>
                
                    {meta.touched && meta.error && (
                        <MyError>
                            {meta.error}
                        </MyError>
                    )}
                </InputWrap>
            )}
        </Field>
    </>)
}
const InputWrap = styled.div`
    width:100%;
    margin-bottom:2em;
    position:relative;
    display: flex;
    align-items:center;
    label{
        margin-right: .5em;
        display: block;
       width: 100px;
    }
`
const MyStyledInput = styled.input`
	padding: 1em .8em;
	width:100%;
    display:block;
	margin-bottom:1em;
    border:1px solid ${props => props.theme.bordes};
    background-color: ${props => props.theme.section};
    outline: none;
    color:${props => props.theme.letras};
    transition: all .2s ease-in;
    display: none;
    &:focus{
        border:1px solid ${props => props.theme.blur};
        color:${props => props.theme.titulos};
    }
    &.error{
        border:1px solid red;
    }
`

const MyError = styled.div`
    color:red;
    padding:.3em;
    position:absolute;
    top:110%;
    font-size:12px;
    z-index: 9996;
`
export default File