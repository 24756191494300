import React from 'react';
import {createGlobalStyle,ThemeProvider} from 'styled-components';
import {GlobalesProvider} from './providers/context';
import { AuthProvider } from './providers/auth'
import {BrowserRouter as Router,Switch,Route} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Theme from './css/Theme'
import 'react-toastify/dist/ReactToastify.min.css';
import './css/pretty-checkbox.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
//PAGES
import Dashboard from './pages/Dashboard'
import Login from './pages/Login'
import MiCuenta from './pages/MiCuenta'
//RECETAS
import RecetasIndex from './pages/recetas/Index'
import RecetasEdit from './pages/recetas/Edit'
import RecetasAdd from './pages/recetas/New'
//DESCARGAS
import DescargasIndex from './pages/descargas/Index'
//DESCARGAS
import ComprasIndex from './pages/compras/Index'



function App() {
	return (
        <AuthProvider>
		<GlobalesProvider>
            <ThemeProvider theme={Theme}>
                <Router>
                    <GlobalStyle/>  
                    <ToastContainer
                        position="top-right"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <Switch>
                        {/* GENERALES */}
                        <Route path="/login" component={Login}/>
                        <Route path="/" exact component={Dashboard}/>
                        <Route path="/mi-cuenta" component={MiCuenta}/>
                        {/* RECETAS */}
                        <Route path="/recetas"  exact component={RecetasIndex}/>
                        <Route path="/receta/:id"  component={RecetasEdit}/>
                        <Route path="/receta-add"  component={RecetasAdd}/>
                        {/* DESCARGAS */}
                        <Route path="/descargas" exact component={DescargasIndex}/>
                        {/* COMPRAS */}
                        <Route path="/compras" exact component={ComprasIndex}/>
                    </Switch>
                </Router>
            </ThemeProvider>
  		</GlobalesProvider>
        </AuthProvider>
	);
}

const GlobalStyle = createGlobalStyle`
    html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td{background: transparent;border: 0;margin: 0;padding: 0;vertical-align: baseline} a{text-decoration:none}
    /*GENERALES*/
    *, *:after ,*:before{
        -ms-box-sizing:border-box;
        -moz-box-sizing:border-box;
        -webkit-box-sizing:border-box;
        box-sizing:border-box;
    }
    .animado, a{
        -ms-transition: all .3s ease-out;
        -moz-transition: all .3s ease-out;
        -webkit-transition: all .3s ease-out;
        transition: all .3s ease-out;
    }
    .wrap{
        width: 90%;
		margin: auto;
    }
    html,body{
        width: 100%;
        height: 100%;
        background: #f4f8fb;
    }
    body{
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        color:#6e6e6e;
    }
    .flex{
		display: flex;
		justify-content: space-between;
		.col2{
			width:50%
		}
    }
    .MuiList-root,.MuiPaper-root{
        z-index: 9999!important;
        
    }
    .activeLink > div{
        background: #3a3f51!important;
        color:#d0cfcf!important;
    }
    .react-confirm-alert-overlay{
        background: transparent!important;
    }
    .confirm{

        display: flex;
        justify-content: center;
        align-items:center;
        padding: 3em 3em;
        background-color: #1e1e2f;
        border:1px solid #424351;
        border-radius: .3rem;
        text-align: center;
        h1{
            color:#76838f;
        }
        p{
            color:#b1b1b5;
            margin-bottom:1em;
        }
        .botonera{
            width: 100%;
            display: flex;
            justify-content: space-between;
            button{
                width: 48%;
                border:none;
                outline: none;
                background-color: #3a3f51;
                color:#b1b1b5;
                padding: .5em 1em;
            }
        }    
    }
    #cuenta{
            position: fixed;
            z-index: 9999!important;
            margin-top: 30px;
        }
`

export default App;
