import React from 'react'
import styled from 'styled-components'


const Title = (props)=>{
    return (
        <Wrap>
            <h1>{props.children}</h1>
        </Wrap>
    )
}
const Wrap = styled.div`
    h1{
        font-size: 1.8em;
        margin-bottom: 1rem;
        font-weight:normal;
    }
`
export default Title