import React, {useContext} from 'react';
import styled from 'styled-components';
import ButtonAside from '../components/ButtonAside'
import {GlobalesContext} from '../providers/context';
const Aside = () => {
    const { state: { menuStatus } } = useContext(GlobalesContext);
    return(
        <MenuWrap className={menuStatus ? '' : 'open'}>
            <ButtonAside 
                path="/" exact 
                title="Dashboard" 
                icon="home"/>
            <ButtonAside 
                path="/recetas" 
                title="Recetas" 
                icon="description"/>
            <ButtonAside 
                path="/descargas" 
                title="Descargas" 
                icon="system_update_alt"/>
            <ButtonAside 
                path="/compras" 
                title="Compras" 
                icon="monetization_on"/>
        </MenuWrap>
    ) 
}
const MenuWrap = styled.aside`
    width: 50px;
    height: 100vh;
    position: fixed;
    z-index: 9997;
    transition: all .3s ease-in;
    background: #1e1e2f;
    color:#FFF;
    overflow-x:hidden;
    padding-top: 60px;
    &.open{
        width: 200px;
    }
    span{
        color: #FFF;
    }
    .openSwitcher{
        width: 50px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom:20px;
        transition: all .3s ease-out; 
        border-bottom:1px solid #424351;
        &:hover{
            color:#69ff96;
        }
    }
`
export default Aside;