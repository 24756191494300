import React from 'react';
import styled from 'styled-components';
import {NavLink} from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
const ButtonAside = props => {
    return(
        <NavLink exact={props.exact} to={props.path} activeClassName="activeLink">
            <LinkStyled>
                <IconWrap>
                    <Icon>{props.icon}</Icon>
                </IconWrap>
                {props.title}
            </LinkStyled>
        </NavLink>
    )
    
}
const LinkStyled = styled.div`
    width:200px;
    display:flex;
    align-items:center;
    color:#8c909a;
    padding: 4px 0;
    margin-bottom:.3em;
    transition: all .3s ease-in ;
    &:hover span,&:hover{
        background: #3a3f51;
        color:#d0cfcf;
    }
`
const IconWrap = styled.div`
    width: 50px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    .material-icons{
        transition: all .3s ease-in ;
        color:#8c909a;
        font-size: 1.2rem;
    }

`
export default ButtonAside;
