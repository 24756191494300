import React from 'react';
import styled from 'styled-components'
const Flex = (props) =>{
    return(
        <Wrap {...props}>
            {props.children}
        </Wrap>
    ) 
}
const Wrap = styled.div`
    display: flex;
    justify-content: ${props => props.h ? props.h : 'space-between'};
    align-items: ${props => props.v ? props.v : 'strech'};
    flex-wrap: wrap;
`
export default Flex; 