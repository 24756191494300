import React, { createContext, useState, useContext, useEffect } from 'react'
import Cookies from 'js-cookie'

import api from '../api';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        async function loadUserFromCookies() {
            const token = await Cookies.get('token')
            api.defaults.headers.authorization = `${token}`
            if (token) {
                api.defaults.headers.authorization = `${token}`
                await api.get('/me')
                .then(function (response) {
                    setUser(response.data)
                    setLoading(false)
                })
                .catch(function (error) {
                    Cookies.remove('token')
                    setUser(null)
                    delete api.defaults.headers.Authorization                 
                }); 
            }      
        }
        loadUserFromCookies()
    }, [])
    return (
        <AuthContext.Provider value={{ isAuthenticated: !!user, user, setUser, loading }}>
            {children}
        </AuthContext.Provider>
    )
}



export const useAuth = () => useContext(AuthContext)



/*


const login = async (email, password) => {
        const { data: token } = await api.post('auth/login', { email, password })
        if (token) {
            console.log("Got token")
            Cookies.set('token', token, { expires: 60 })
            api.defaults.headers.Authorization = `Bearer ${token.token}`
            const { data: user } = await api.get('users/me')
            setUser(user)
            console.log("Got user", user)
        }
    }

    const logout = (email, password) => {
        Cookies.remove('token')
        setUser(null)
        delete api.defaults.headers.Authorization
        window.location.pathname = '/login'
    }

*/