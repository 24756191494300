// api.js
import axios from "axios";

let urls = {
    test: 'http://localhost:8000',
    development: 'http://localhost:8000',
    production: 'https://api.mindfood.mx'
}
const api = axios.create({
    baseURL: urls[process.env.NODE_ENV],
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

export default api;