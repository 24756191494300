import React, {useEffect, useState} from 'react'
import api from '../api'
import withLayoutAuth from '../layout/withLayoutAuth'
import Section from '../components/Section'
import Skeleton from '../components/Skeleton'
import Title from '../components/Title'
import UpadateForm from '../components/miCuenta/UpdateForm'
import {useAuth} from '../providers/auth'
const MiCuenta = () =>{
    const { user } = useAuth();
    const [cargando, setCargando] = useState(true)
    const [data, setData] = useState([])
    useEffect(()=>{
        api.get(`/users/${user.id}`)
        .then(response=>{
            setData(response.data);
            setCargando(false)
        })
    },[cargando,user.id])
    return(
        <Section>
            <Title>Mi Cuenta</Title>
            {cargando &&
                <>
                    
                    <Skeleton />
                </>
            }
            {!cargando &&
                <>
                    <UpadateForm data={data}/>
                </>
            }
        </Section>
    )
}
export default withLayoutAuth(MiCuenta)