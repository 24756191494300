import React from 'react'
import { Field } from 'formik';
import styled from 'styled-components'
const Input = ({name,defaultId,label}) => {
    return(<>
        <Field name={name}>
            {({
                field,
                form, 
                meta,
            }) => (
                <InputWrap>
                    {label && 
                        <label>{label}</label>
                    }
                    <MyStyledSelect 
                        className={meta.touched && meta.error ? 'error' : ''}
                        {...field} 
                    >
                        <option value=''>Selecciona un tipo de receta</option>
                        <option value="bebida">Bebida</option>
                        <option value="Desayuno">Desayuno</option>
                        <option value="Sopa">Sopa</option>
                        <option value="Ensalada">Ensalada</option>
                        <option value="Platos fuertes">Platos fuertes</option>
                        <option value="Pastas">Pastas</option>
                        <option value="Guarniciones">Guarniciones</option>
                        <option value="Botana">Botana</option>
                        <option value="Postres">Postres</option>
                        <option value="Snack/lunches">Snack/lunches</option>
                    </MyStyledSelect>
                    {meta.touched && meta.error && (
                        <MyError>
                            {meta.error}
                        </MyError>
                    )}
                </InputWrap>
            )}
        </Field>
    </>)
}
const InputWrap = styled.div`
    width:100%;
    margin-bottom:1em;
    position:relative;
    label{
        margin-bottom: .3em;
        display: block;
    }
`
const MyStyledSelect = styled.select`
	padding: 1em .8em;
	width:100%;
    display:block;
	margin-bottom:1em;
    border:1px solid ${props => props.theme.bordes};
    background-color: ${props => props.theme.section};
    outline: none;
    color:${props => props.theme.letras};
    transition: all .2s ease-in;
    &:focus{
        border:1px solid ${props => props.theme.blur};
        color:${props => props.theme.titulos};
    }
    &.error{
        border:1px solid red;
    }
    
`
const MyError = styled.div`
    color:red;
    padding:.3em;
    position:absolute;
    top:100%;
    transform: translateY(-50%);
    font-size:12px;
    z-index: 9996;
`
export default Input