import React from 'react'
import styled from 'styled-components'
import Form from './Form'
const LoginContent = () =>{
    return(
        <Wrap>
            <img src="/svg/logo.svg"/>
            <Form/>
        </Wrap>
    )
}
const Wrap = styled.section`
    width: 80%;
    margin:auto;
    img{
        display: block;
        max-width: 200px;
        margin: auto;
        margin-bottom: 2rem;
    }
`
export default LoginContent