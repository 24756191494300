const Theme = {
    bkColor : '#1e1e2f',
    section: '#27293d',
    action: '#4d29ff',
    bordes: '#424351',
    blur:'#80bdff',
    letras: '#76838f',
    titulos: '#b1b1b5',
}

export default Theme