import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon';
const Options = (props) => {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = (event) => {
		setAnchorEl(null);
	};
	const handleEdit = (id, editar) => {
		editar(id)
		setAnchorEl(null);
	};
	const handleDelete = (id, borrar) => {
		borrar(id)
		setAnchorEl(null);
	};
  	return (
		<div>
			<Button 
				aria-controls="simple-menu" 
				aria-haspopup="true" 
				onClick={handleClick}>
				<Icon>more_vert</Icon>
			</Button>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}>

				<MenuItem onClick={()=>handleEdit(props.id, props.editar)}>Editar</MenuItem>
				<MenuItem onClick={()=>handleDelete(props.id, props.eliminar)}>Eliminar</MenuItem>
			</Menu>
		</div>
 	 );
}

export default Options