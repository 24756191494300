import React, {useState} from 'react'
import styled from 'styled-components'

const EditImage = ({children,url}) => {
    const [editable,setEditable] = useState(false);
    const editImage = () => {
        setEditable(true)
    }
    return(<>
        <Wrap>
            {!editable &&      
                <Image>
                    <img src={`https://api.mindfood.mx/uploads/${url}`} alt=""/>
                    <div className="tache" onClick={editImage}>
                        <img src="/svg/tache.svg" alt="Eliminar"/>
                    </div>
                </Image>
            }
            {editable &&
                children
            }
        </Wrap>
    </>)
}
const Wrap = styled.div`
    
`
const Image = styled.div`
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items:center;
    overflow: hidden;
    position: relative;
    img{
        display: block;
        width: 100%;
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);  
    }
    .tache{
        position: absolute;
        right: 4px;
        top: 4px;
        transition: all 0.3s ease-in;
        cursor: pointer;
        img{
            display: block;
            width: 25px;
        }
        &:hover{
            transform: scale(1.05);
        }
    }
`
export default EditImage