import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import Icon from '@material-ui/core/Icon';
const Thumb = ({img,nombre}) => {
    const[url,setUrl] = useState('')
    useEffect(()=>{
        if(img){
            let reader = new FileReader();
            reader.onloadend = () => {
                setUrl(reader.result);
            };
            reader.readAsDataURL(img)
        }
    },[img])
    return(<>
        <Circle>
            <div className="containerLabel">
                {!img &&
                    <Icon>add_a_photo</Icon>
                }
                <label htmlFor={nombre}></label>
                
            </div>
            
            {img &&
                <img src={url} alt=""/>
            }
        </Circle>
    </>)
}
const Circle = styled.div`
    width: 150px;
    height: 100px;
    
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items:center;
    border:1px solid ${props => props.theme.bordes};
    img{
        width: 110%;
    }
    .containerLabel{
        width: 150px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items:center;
        position: absolute;
        z-index: 9888;
    }
    label{
        width: 150px;
        height: 100px;
        display: block;
        position: absolute;
        z-index: 9889;
    }
`
export default Thumb;