import React, {useEffect,useState} from 'react'
import api from '../../api'
import { Field } from 'formik';
import styled from 'styled-components'
import { Multiselect } from 'multiselect-react-dropdown';
const File = ({name,label,update}) => {
    const [options, setOptions] =useState([])
    const [selected, setSelected] = useState([])
    useEffect(()=>{
        if(update !== ''){
            setSelected(update)
        }
        api.get('/categorias')
        .then(response=>{
            setOptions(response.data)
        })
    },[])
    const onSelect = (selectedList, selectedItem) => {

    }
    const onRemove = () => {
        
    }
    return(<>
        <Field name={name}>
            {({
                field,
                form, 
                meta,
            }) => (
                <InputWrap>
                    {label && 
                        <label>{label}</label>
                    }
                    <Multiselect
                        name={name}
                        {...field}
                        placeholder="Selecciona categorías"
                        options={options}
                        selectedValues={selected}
                        displayValue="title"
                        onSelect={selectedList => {
                            form.setFieldValue(name, selectedList)
                        }}
                        onRemove={selectedList => {
                            form.setFieldValue(name, selectedList)
                        }}
                    />
                    
                </InputWrap>
            )}
        </Field>
    </>)
}
const InputWrap = styled.div`
    width:100%;
    margin-bottom:1em;
    position:relative;
    label{
        margin-bottom: .3em;
        display: block;
    }
    #multiselectContainerReact > div:first-child{
        padding: 1em .8em;
        width:100%;
        display:block;
        margin-bottom:1em;
        border:1px solid ${props => props.theme.bordes};
        background-color: ${props => props.theme.section};
        outline: none;
        color:${props => props.theme.letras}!important;
        transition: all .2s ease-in;
    }
    #search_input{
        color:${props => props.theme.letras}!important;
    }
`
const MyStyledInput = styled.input`
	padding: 1em .8em;
	width:100%;
    display:block;
	margin-bottom:1em;
    border:1px solid ${props => props.theme.bordes};
    background-color: ${props => props.theme.section};
    outline: none;
    color:${props => props.theme.letras};
    transition: all .2s ease-in;
    &:focus{
        border:1px solid ${props => props.theme.blur};
        color:${props => props.theme.titulos};
    }
    &.error{
        border:1px solid red;
    }
    
`

export default File