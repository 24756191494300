import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import api from '../../api'
import withLayoutAuth from '../../layout/withLayoutAuth'
import Section from '../../components/Section'
import Skeleton from '../../components/Skeleton'
import Title from '../../components/Title'
import Edit from '../../components/recetas/Edit'
const Single = () =>{
    let { id } = useParams();
    const [cargando, setCargando] = useState(true)
    const [data, setData] = useState([])
    useEffect(()=>{
        api.get(`/recetas/${id}`)
        .then(response=>{
            setData(response.data);
            setCargando(false)
        })
        .catch(err=>{
            console.log(err)
        })
    },[cargando,id])
    return(
        <Section>
            <Title>{data.name}</Title>
            {cargando &&
                <>
                    <Skeleton />
                </>
            }
            {!cargando &&
                <>
                    <Edit data={data}/>
                </>
            }
        </Section>
    )
}

export default withLayoutAuth(Single)