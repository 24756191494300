import React from 'react'
import withLayoutAuth from '../layout/withLayoutAuth'
import Generales from '../components/dashboard/Generales'
const Dashboard = () =>{
    return(
        <section>
            <Generales/>
        </section>
    )
}
export default withLayoutAuth(Dashboard)