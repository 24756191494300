import React from 'react'
import styled from 'styled-components'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
const RecetaSkeleton = ({data})=>{
    return (
        <SkeletonTheme color="#1e1e2f" highlightColor="#27293d">
        <Wrap>
            {/* <Skeleton height={250} className="gde"/> */}
            <Skeleton count={12} className="ch"/>
        </Wrap>
        </SkeletonTheme>
    )
}
const Wrap = styled.div`
    width: 100%;
    .gde{
        margin-bottom: 1em;
    }
    .ch{
        margin-bottom: 1em;
    }
`
export default RecetaSkeleton