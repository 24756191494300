import React from 'react'
import styled from 'styled-components'
import Flex from '../components/Flex'
import LoginContent from '../components/Login/LoginContent'
const Login = () => {
    return(
        <Flex>
            <FormWrap>
                <LoginContent/>
            </FormWrap>
            <Image />
        </Flex>
    )
}
const FormWrap = styled.section`
    width: 50%;
    min-height: 100vh;
    background: #1e1e2f;
    display: flex;
    justify-content: center;
    align-items: center;
`
const Image = styled.section`
    width: 50%;
    min-height: 100vh;
    background: url(../img/login.jpg) 50% 50%;
    background-size:cover;
`
export default Login;
