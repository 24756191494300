import React from 'react'
import { Formik } from 'formik';
import * as Yup from 'yup';
import BottonFull from '../ButtonFull'
import LoadingSmall from '../LoadingSmall'
import Input from '../forms/Input'
import api from '../../api'
import styled from 'styled-components'
import {toast} from 'react-toastify'
import { useHistory} from "react-router-dom";
import {useAuth} from '../../providers/auth'
const Edit = ({data}) => {
    let history = useHistory();
    const { user } = useAuth();
    return(
        <Formik
            initialValues={{
                nombre: data.nombre,
                apellido: data.apellido,
                email: data.email,
                password: '',
                confirmPassword: '',

            }}
            validationSchema={Yup.object({
                nombre: Yup.string()
                    .required('Este campo es obligatorio'),
                apellido: Yup.string()
                    .required('Este campo es obligatorio'),
                email: Yup.string()
                    .email('Debes colocar un email válido')
                    .required('Este campo es obligatorio'),
                confirmPassword: Yup.string()
                    .oneOf([Yup.ref('password'), null], 'Contraseñas deben ser iguales'),
            })}
            onSubmit={(values, {setSubmitting}) => {
                setSubmitting(true);        
                api.post(`/users/${user.id}`, values)
                .then(response=>{
                    toast.success(
                        'Elemento actualizado correctamente',
                        {
                            onClose: () => history.push('/')
                        }
                    )
                })
                .catch(err=>{
                    console.log(err)
                })
            }}
            >
            {formik => (               
                <MyForm onSubmit={formik.handleSubmit}>
                    <Input 
                        name="nombre" 
                        placeholder="nombre"
                        label="Nombre"
                        type="text"/> 
                    <Input 
                        name="apellido" 
                        placeholder="Apellido"
                        label="Apellido"
                        type="text"/>
                    <Input 
                        name="email" 
                        placeholder="Email"
                        label="Email"
                        type="text"/> 
                    <Input 
                        name="password" 
                        placeholder="Password"
                        label="Password"
                        type="password"/>
                    <Input 
                        name="confirmPassword" 
                        placeholder="Reingresa tu password"
                        label="Reingresa tu password"
                        type="password"/>
                    <BottonFull disabled={formik.isSubmitting}>
                        {formik.isSubmitting ? <LoadingSmall/> : 'Guardar'}
                    </BottonFull>
                </MyForm>
            )}
        </Formik>
    )
}
const MyForm = styled.form`
    padding: 1em 0 2em 0;
	width:100%;
	display:flex;
	justify-content:space-between;
    flex-wrap:wrap;
    @media screen and (max-width: 768px) {
        width: 90%;
    }
`
export default Edit