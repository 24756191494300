import React from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom';
const ButtonFull = (props) => {
    return(
        <Wrap {...props}>
            {props.link && 
                <Link href={props.link}>
                    <button>{props.children}</button>
                </Link>  
            }
            {!props.link && 
                <button disabled={props.disabled} type="submit">{props.children}</button>
            }   
        </Wrap>
    )
}
const Wrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    button{ 
        width: 100%;
        display: block;
        background:${props => props.theme.action};
        border:none;
        outline: none;
        color:#FFF;
        padding: 1em 1em;
        transition: all .3s ease-out;
        &:hover{
            transform: scale(1.005);
        }
        &:disabled{
            opacity: 0.5;
            &:hover{
                transform: scale(1);
            }   
        }
    }
`


export default ButtonFull;