import React, {useEffect, useState} from 'react'
import api from '../../api'
import withLayoutAuth from '../../layout/withLayoutAuth'
import Section from '../../components/Section'
import Skeleton from '../../components/Skeleton'
import Title from '../../components/Title'
import Tabla from '../../components/descargas/Tabla'
const IndexEstudios = () =>{
    const [cargando, setCargando] = useState(true)
    const [data, setData] = useState([])
    useEffect(()=>{
        api.get('/descargas')
        .then(response=>{   
            setData(response.data);
            setCargando(false)
        })
        .catch(err=>{
            console.log(err)
        })
    },[cargando])
    return(
        <Section>
            <Title>Descargas</Title>
            {cargando &&
                <>     
                    <Skeleton />
                </>
            }
            {!cargando &&
                <>
                    <Tabla data={data} carga={setCargando}/>
                </>
            }
        </Section>
    )
}

export default withLayoutAuth(IndexEstudios)