import React, {useState} from 'react'
import styled from 'styled-components'
import Avatar from '@material-ui/core/Avatar';
import {toast} from 'react-toastify'
import { Link, useHistory} from "react-router-dom";
import DataTable, { createTheme } from 'react-data-table-component';
import Options from '../Options'
import { confirmAlert } from 'react-confirm-alert';
import Flex from '../Flex'
import api from '../../api';
const Tabla = (props) => {
    const {data,carga} = props;
    console.log(data)
    const [filterText, setFilterText] = useState('');
    const filteredItems = data.filter(item => item.title && item.title.toLowerCase().includes(filterText.toLowerCase()));
    let history = useHistory();
    const deleteItem = (id) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='confirm'>
                        <div>
                            <h1>¿Estás seguro?</h1>
                            <p>¿Quieres borrar esta receta?</p>
                            <div className="botonera">
                                <button onClick={()=>{
                                    onClose();
                                }}>No</button>
                                <button
                                    onClick={() => {
                                        carga(true)
                                        api.delete(`/recetas/${id}`)
                                        .then(response=>{
                                            toast.success('Receta eliminada')
                                            onClose();
                                        })
                                        .catch(err=>{
                                            toast.error('No pudimos eliminar esta receta')
                                            onClose();
                                        })
                                        
                                    }}>
                                    !Sí, eliminar!
                                </button>
                            </div>  
                        </div>
                    </div>
                );
            }
        });
    }
    const goToEdit = (id) =>{
        history.push(`/receta/${id}`)
    }
    const columns = [
        {
            name: 'Nombre',
            selector: 'title',
            sortable: true,

        },
        {
            name: 'Opciones',
            cell:(row) => {
                return <Options 
                        id={row.id} 
                        eliminar={deleteItem}
                        editar={goToEdit}/>
            },
            right: true,
            grow:1,
        },
    ];
    const filtrado = e => {
        e.preventDefault()
        setFilterText(e.target.value)
    }
    return(<>
        <Flex v="center">  
            <Buscador>
                <Filtro 
                    type="text" 
                    key="miinPutEstoPrueba"
                    placeholder="Buscar..." 
                    value={filterText}
                    onChange={filtrado}
                />
                {filterText !== '' &&
                    <Reset 
                        type="button" 
                        onClick={() => setFilterText('')}>X</Reset>
                }
            </Buscador>
            <New>
                <Link to="/receta-add">+</Link>  
            </New>
        </Flex>
        <MyTable>
            <DataTable
                data={filteredItems}
                columns={columns}
                theme="estudio"
                pagination={true}
                noDataComponent="No existen recetas"
                paginationPerPage={10}
            />
        </MyTable>
    </>)
}
const MyTable = styled.div`
    width: 100%;
    padding: 0;
    .rdt_TableRow{
        &:nth-child(2n-1){
            background: ${props => props.theme.bkColor};
        }
        &:nth-child(2n){
            background: transparent;
        }
    }
    .rdt_TableHeader{
        display: none;
    }
    .rdt_Pagination svg{
        fill:#b1b1b5;
    }
    #pagination-next-page{
        fill:#b1b1b5;
    }
    .MuiAvatar-root{
        width: 28px;
        height: 28px;
        font-size: .8rem;
    }
    .material-icons{
        color:${props => props.theme.titulos};
        margin-left:20px;
    }
`
const Buscador=styled.div`
    position: relative;
    width: 50%;
`
const Filtro = styled.input`
    padding: 1em .8em;
	width:100%;
    display:block;
	margin-bottom:1em;
    border:1px solid ${props => props.theme.bordes};
    background-color: ${props => props.theme.section};
    outline: none;
    color:${props => props.theme.letras};
    transition: all .2s ease-in;
    &:focus{
        border:1px solid ${props => props.theme.blur};
        color:${props => props.theme.titulos};
    }
`
const Reset = styled.button`
    background:transparent;
    border:none;
    outline: none;
    color:#FFF;
    padding: 0 1rem;
    height: 40px;
    position: absolute;
    top:40%;
    right: 1px;
    transform: translate(0,-50%);
    transition: all .3s ease-out;
    cursor: pointer;
    &:hover{
        color:red;
    }
`
const New = styled.button`
    width: 40px;
    height: 40px;
    background-color: ${props => props.theme.action};
    border:none;
    outline:none;
    display: flex;
    justify-content: center;
    align-items:center;
    margin-right:.5em;
    color:#FFF;
    border-radius:100%;
    font-size:2em;
    line-height: 0;
    cursor: pointer;
    transition:.2s all ease-in;
    a{
        color:#FFF; 
        margin-left:1px;
        margin-top:-4px;
    }
    &:hover{
        transform: scale(1.05);
    }
`
createTheme('estudio', {
    text: {
      primary: '#b1b1b5',
      secondary: '#76838f',
    },
    background: {
      default: 'transparent',
    },
    divider: {
      default: '#424351',
    },
});
export default Tabla