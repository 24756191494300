import React from 'react'
import { Field } from 'formik';
import styled from 'styled-components'
const Checkbox = ({name,label}) => {
    return(<>
        <Field name={name}>
            {({
                field,
                form: { touched, errors }, 
                meta,
            }) => (
                <InputWrap>
                    <div className="pretty p-default">
                        <input type="checkbox" checked={field.value} {...field}/>
                        <div className="state">
                            <label>{label}</label>
                        </div>
                    </div>
                    {meta.touched && meta.error && (
                        <MyError>
                            {meta.error}
                        </MyError>
        
                    )}
                </InputWrap>
            )}
        </Field>
    </>)
}
const InputWrap = styled.div`
    width:100%;
    margin-bottom:2em;
    position:relative;
`
const MyError = styled.div`
    color:red;
    padding:.3em;
    position:absolute;
    top:18px;
    font-size:12px;
    z-index: 9999;
`
export default Checkbox