import React from 'react';
import styled from 'styled-components'

const Grid = (props) =>{
    return(
        <Wrap {...props}>
                {props.children}
        </Wrap>
    ) 
}
const Wrap = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(${({ columns }) => columns ? columns : '3'},1fr);
    grid-column-gap: 2.5%;
    margin-bottom:${({ margin }) => margin ? margin : '0'};
    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2,1fr);
        grid-row-gap: 2em;
    }
    @media screen and (max-width: 480px) {
        grid-template-columns: repeat(1,1fr);
        grid-row-gap: 1em;
    }
`
export default Grid; 