import React, {useState, useEffect} from 'react';
import Grid from '../Grid'
import Section from '../Section'
import styled from 'styled-components'
import Icon from '@material-ui/core/Icon';
import AnimatedNumber from "animated-number-react";
import api from '../../api'
const Generales = ()=>{
    const [values,setValues] = useState([]) 
    const formatValue = value => value.toFixed(0);
    useEffect(()=>{
        api.get('/estadisticas')
        .then(response => {
            setValues(response.data)
        })
    },[])
    return(
        <Grid columns="2">
            <Section>
                <Dato>
                    <h2>Total de recetas</h2>
                    <div className="info">
                        <div className="num">
                        <AnimatedNumber
                            value={values.recetas}
                            duration="2000"
                            formatValue={formatValue}
                            />
                        </div>
                        <Icon>description</Icon>
                    </div>
                </Dato>
            </Section>
            <Section>
                <Dato>
                    <h2>Total de categorías</h2>
                    <div className="info">
                        <div className="num">
                            <AnimatedNumber
                                value={values.categorias}
                                duration="2000"
                                formatValue={formatValue}
                                />
                        </div>
                        <Icon>category</Icon>
                    </div>
                </Dato>
            </Section>
            <Section>
                <Dato>
                    <h2>Total de descargas</h2>
                    <div className="info">
                        <div className="num">
                            <AnimatedNumber
                                value={values.descargas}
                                duration="2000"
                                formatValue={formatValue}
                                />
                        </div>
                        <Icon>system_update_alt</Icon>
                    </div>
                </Dato>
            </Section>
            <Section>
                <Dato>
                    <h2>Total de compras</h2>
                    <div className="info">
                        <div className="num">
                            <AnimatedNumber
                                value={values.compras}
                                duration="2000"
                                formatValue={formatValue}
                                />
                        </div>
                        <Icon>monetization_on</Icon>
                    </div>
                </Dato>
            </Section>
        </Grid>
    )
}
const Dato = styled.div`
    h2{
        font-size: .8rem;
        font-weight: normal;
    }
    .info{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: .5em;
        .num{

            font-size: 2rem;
        }
        .material-icons{
            font-size: 2.5rem;
        }
    }
`
export default Generales