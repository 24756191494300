import React from 'react'
import styled from 'styled-components'
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory,useLocation } from "react-router-dom";
import BottonFull from '../ButtonFull'
import {toast} from 'react-toastify'
import Cookies from 'js-cookie'
import {useAuth} from '../../providers/auth'
import LoadingSmall from '../LoadingSmall'
import Checkbox from '../forms/Checkbox'
import Input from '../forms/Input'
import api from '../../api'
const Form = () => {
    let history = useHistory();
    const location = useLocation();
    const { setUser } = useAuth();
    return(
        <Formik
            initialValues={{
                email: '',
                password: '',
                remember:true
            }}
            validationSchema={Yup.object({
                email: Yup.string()
                    .email('Debes colocar un email válido')
                    .required('Este campo es obligatorio'),
                password: Yup.string()
                    .required('Este campo es obligatorio'),
                remember: Yup.boolean()
                    .required('Este campo es obligatorio')
                    // .oneOf([true], 'Este campo es obligatorio')
            })}
            onSubmit={async (values, {setSubmitting }) => {
                setSubmitting(true);
                await api.post('/login',values)
                .then(function (response) {
                    if(response.data.level === 88){
                        if(values.remember){
                            Cookies.set('token', response.data.api_token, { expires: 60 })
                        }else{
                            Cookies.set('token', response.data.api_token, { expires: 1 })
                        }
                        api.defaults.headers.authorization = `${response.data.api_token}`
                        setUser(response.data)
                        if(location.pathname === '/login'){
                            history.push('/')
                        }
                    }else{
                        toast.error('Usuario no válido')
                    }
                })
                .catch(function (error) {
                    toast.error(
                        error.response.data,
                        {
                            onOpen: () => setSubmitting(false)
                        }
                    )
                }); 
            }}
            >
            {formik => (               
                <MyForm onSubmit={formik.handleSubmit}>	
                    <Input 
                        name="email" 
                        placeholder="Email"
                        type="text"/>  
                    <Input 
                        name="password" 
                        placeholder="Password"
                        type="password"/>
                    <Checkbox 
                        name="remember" 
                        label="Recuérdame"/>         
                    <BottonFull disabled={formik.isSubmitting}>
                        {formik.isSubmitting ? <LoadingSmall/> : 'Iniciar sesión'}
                    </BottonFull>
                </MyForm>
            )}
        </Formik>
    )
}
const MyForm = styled.form`
    padding: 3.5em 0;
	width:100%;
	display:flex;
	justify-content:space-between;
    flex-wrap:wrap;
    @media screen and (max-width: 768px) {
        width: 90%;
    }
`
export default Form