import React from 'react'
import { Field } from 'formik';
import styled from 'styled-components'
const Input = ({name,placeholder,type,label}) => {
    return(<>
        <Field name={name}>
            {({
                field,
                form: { touched, errors }, 
                meta,
            }) => (
                <InputWrap>
                    {label && 
                        <label>{label}</label>
                    }
                    <MyStyledInput 
                        type={type}
                        placeholder={placeholder} 
                        className={meta.touched && meta.error ? 'error' : ''}
                        {...field} 
                    />
                    {meta.touched && meta.error && (
                        <MyError>
                            {meta.error}
                        </MyError>
                    )}
                </InputWrap>
            )}
        </Field>
    </>)
}
const InputWrap = styled.div`
    width:100%;
    margin-bottom:1em;
    position:relative;
    label{
        margin-bottom: .3em;
        display: block;
    }
`
const MyStyledInput = styled.input`
	padding: 1em .8em;
	width:100%;
    display:block;
	margin-bottom:1em;
    border:1px solid ${props => props.theme.bordes};
    background-color: ${props => props.theme.section};
    outline: none;
    color:${props => props.theme.letras};
    transition: all .2s ease-in;
    &:focus{
        border:1px solid ${props => props.theme.blur};
        color:${props => props.theme.titulos};
    }
    &.error{
        border:1px solid red;
    }
    &:-webkit-autofill{
        appearance: inherit;
        background-color: inherit!important;
        background-image: none !important;
        color: inherit !important;
    }
    
`
const MyError = styled.div`
    color:red;
    padding:.3em;
    position:absolute;
    top:100%;
    transform: translateY(-50%);
    font-size:12px;
    z-index: 9996;
`
export default Input