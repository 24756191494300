import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import { useHistory } from "react-router-dom";
import {useAuth} from '../providers/auth'
import api from '../api';
import Cookies from 'js-cookie'
const Account = (props) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const history = useHistory();
    const { user, setUser} = useAuth();
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = (event) => {
		setAnchorEl(null);
	};
	const handleMiCuenta = (id, editar) => {
		history.push('/mi-cuenta')  
		setAnchorEl(null);
	};
	const closeSession = (e) =>{
        e.preventDefault();
        history.push('/')   
        Cookies.remove('token')
        setUser(null)
        delete api.defaults.headers.Authorization 
    }
  	return (
		<div>
			<Button 
				aria-controls="cuenta" 
				aria-haspopup="true" 
				onClick={handleClick}>
				<Avatar>
			
					{/* {user.nombre.charAt(0)}
					{user.apellido.charAt(0)} */}
				</Avatar>
			</Button>
			<Menu
				id="cuenta"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}>

				<MenuItem onClick={handleMiCuenta}>Mi cuenta</MenuItem>
				<MenuItem onClick={closeSession}>Salir</MenuItem>
			</Menu>
		</div>
 	 );
}

export default Account