import React from 'react';
import Header from './Header';
import Aside from './Aside';
import Content from './Content';
import Login from '../pages/Login'
import {useAuth} from '../providers/auth'
function withLayoutAuth(Wrapped) {
    return function () {   
        const { isAuthenticated, isLoading } = useAuth();
        if (isLoading || !isAuthenticated){
            return <Login />
        }else{
            return (<>
                <div className='container'>
                    <Header />
                    <Aside />
                    <Content>
                        <Wrapped />
                    </Content>
                </div>
            </>)
        }   
    }
}

export default withLayoutAuth

