import React, {useState} from 'react'
import { Formik } from 'formik';
import * as Yup from 'yup';
import BottonFull from '../ButtonFull'
import LoadingSmall from '../LoadingSmall'
import Input from '../forms/Input'
import File from '../forms/File'
import MultiSelect from '../forms/MultiSelect'
import EditImage from '../forms/EditImage'
import api from '../../api'
import styled from 'styled-components'
import {toast} from 'react-toastify'
import { useHistory} from "react-router-dom";
import TipoSelect from '../forms/TipoSelect'
import Pdf from '../forms/Pdf'
import Grid from '../Grid'
import PDFViewer from 'pdf-viewer-reactjs'
const Edit = ({data}) => {
    console.log(data)
    const [progress, SetProgress] = useState(0);
    let history = useHistory();
    const SUPPORTED_FORMATS = [
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "image/png"
    ];
    return(
        <Formik     
            initialValues={{
                title: data.title,
                img_gde: '',
                pdf: '',
                tipo: data.tipo,
                categorias:data.categorias
            }}
            validationSchema={Yup.object({
                title: Yup.string()
                    .required('Este campo es obligatorio'),
                img_gde: Yup.mixed()
                    
                    .test(
                        "fileFormat",
                        "Formato no soportado",
                        value => value && SUPPORTED_FORMATS.includes(value.type) || !value
                    ),
                pdf: Yup.string(),
                tipo: Yup.string()
                    .required('Este campo es obligatorio'),
            })}
            onSubmit={(values, {setSubmitting}) => {
                setSubmitting(true); 
                let cats_ids = [];
                const ids = values.categorias.map(categoria=>{
                    cats_ids.push(categoria.id)
                })
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: progressEvent => {
                        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        SetProgress(percentCompleted)
                    }
                }
                const formData = new FormData();
                formData.append('title' , values.title);
                formData.append('img_gde' , values.img_gde);
                formData.append('pdf' , values.pdf);
                formData.append('tipo' , values.tipo);
                cats_ids.map(cat=>{
                    formData.append('categorias[]' , cat);
                })
                api.post(`/recetas-update/${data.id}`, formData, config)
                .then(response=>{
                    console.log(response.data)
                    history.push('/recetas')
                    toast.success('Receta actualizada correctamente')
                })
                .catch(err=>{
                    toast.error(
                        `Error: ${err.response.data.message}`,
                        {
                            onOpen: () => setSubmitting(false)
                        }
                    )
                })
            }}
            >
            {formik => (               
                <MyForm onSubmit={formik.handleSubmit}>  
                    <Input 
                        name="title" 
                        placeholder="Nombre receta"
                        label="Nombre receta"
                        type="text"/> 
                    <Grid columns="2">
                        <EditImage url={data.img_gde}>
                            <File 
                                name="img_gde" 
                                placeholder="Imagen"
                                label="Imagen"/>
                        </EditImage>
                    </Grid>
                    
                    <Descarga>
                        <a href={`https://api.mindfood.mx/uploads/pdf/${data.pdf}`}>
                            Descarga PDF actual
                        </a>
                    </Descarga>
                    <Pdf
                        name="pdf" 
                        placeholder="Receta (PDF)"
                        label="Receta (PDF)"/>
                    <TipoSelect 
                        name="tipo"
                        label="Tipo de receta"
                        placeholder="Tipo de receta"/>
                    <MultiSelect
                        name="categorias"
                        label="Categorías"
                        update={data.categorias}/>
                    <BottonFull disabled={formik.isSubmitting}>
                        {formik.isSubmitting ? <LoadingSmall/> : 'Guardar'}
                    </BottonFull>
                </MyForm>
            )}
        </Formik>
    )
}
const MyForm = styled.form`
    padding: 1em 0 2em 0;
	width:100%;
	display:flex;
	justify-content:space-between;
    flex-wrap:wrap;
    @media screen and (max-width: 768px) {
        width: 90%;
    }
`
const Descarga = styled.div`
    margin:2em 0 1em 0;
    a{
        display: block;
        color:#FFF;
        background-color: #4d29ff;
        padding: .5em 1em;;
    }
`
export default Edit