import React, {useContext} from 'react';
import styled from 'styled-components';
import {GlobalesContext} from '../providers/context';
const Aside = props => {
    const { state: { menuStatus } } = useContext(GlobalesContext);
    return(

        <Content className={menuStatus ? '' : 'open'}>
            <div className="contentWrap">
                {props.children}
            </div>
        </Content>
    )
}

const Content = styled.section`
    width: 100%;
    min-height: 100vh;
    padding: 80px 2% 1em 0;
    padding-left: calc(2% + 50px);
    transition: all .3s ease-in;
    background: ${props => props.theme.bkColor};
    color:${props => props.theme.letras};
    h1,h2,h3,h4,h5,h6{
        color: #b1b1b5;
    }
    &.open{
        padding-left: calc(2% + 200px);
    }
    .contentWrap{
        width: 100%;
        min-height: calc(100vh - 128px);
        padding-bottom:2em;
    }
`
export default Aside;
