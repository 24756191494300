import React, {useContext} from 'react';
import styled from 'styled-components';
import Icon from '@material-ui/core/Icon';
import Avatar from '@material-ui/core/Avatar';
import Account from '../components/Account';
import {GlobalesContext} from '../providers/context';
const Header = (props) => {
    const { state: { menuStatus }, dispatch } = useContext(GlobalesContext);
    const toggleMenu = ()=>{
        menuStatus ? 
        dispatch({menuStatus: false}) :
        dispatch({menuStatus: true})
    }
    return(
        <Head>
            <LogoWrap>
                <div className="logo">
                    <img src="/svg/logo.svg" alt="Mindfood"/>
                </div>
                <Icon onClick={toggleMenu} className="openSwitcher">apps</Icon>
            </LogoWrap>
            
            <div id="user">
                <Account/>
            </div>
        </Head>
    )
}
const Head = styled.header`
    background: ${props => props.theme.bkColor};
    width: 100%;
    height: 50px;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border-bottom:1px solid #424351;
    z-index:9998;
    color:#FFF;
    #user{
        width: 30%;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        padding-right: 30px;
    }
`;
const LogoWrap = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .material-icons{
        transition: all .3s ease-in ;
        color:#8c909a;
        font-size: 1.8rem;
        margin-left: 1em;
        &:hover{
            color:#d0cfcf;
        }
    }
    .logo{
        width: 200px;
        img{
            display: block;
            max-height: 40px;
            margin-left: 4px;
        }
    }
`

export default Header; 