import React from 'react'
import withLayoutAuth from '../../layout/withLayoutAuth'
import Section from '../../components/Section'
import Title from '../../components/Title'
import NewForm from '../../components/recetas/NewForm'
const New = () =>{
    return(
        <Section>
            <Title>Añadir receta</Title>
            <NewForm/>
        </Section>
    )
}

export default withLayoutAuth(New)