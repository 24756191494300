import React from 'react'
import styled from 'styled-components'
const Section = (props) => {
    return(
        <Wrap>
            {props.children}
        </Wrap>
    )
}
const Wrap = styled.section`
    width: 100%;
    background: ${props => props.theme.section};
    margin-bottom: 1.85rem;
    padding: 1.5rem;
`
export default Section;